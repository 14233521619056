.font-primary{
    font-family: 'Marcellus', serif;
}

.bg-custom-gradient {
    background-image: linear-gradient(to bottom, #505E6B 0%, #505E6B 75%, #B99267 75%, #B99267 100%);
}

.bg-custom-gradient1 {
    background-image: linear-gradient(to bottom right, #505E6B 0%, #505E6B 60%, #B99267 60%, #B99267 100%);
}

.bg-primary {
    background-color: #354253;
}

.bg-secondary {
    background-color: #505E6B;
}

.bg-tertiary {
    background-color: #B99267;
}

.bg-light {
    background-color: #F2F2F2;
}

.text-primary {
    color: #354253;
}

.text-secondary {
    color: #505E6B;
}

.text-tertiary {
    color: #B99267;
}

.text-light {
    color: #F2F2F2;
}

.fill-primary {
    fill: #354253;
}

.fill-secondary {
    fill: #505E6B;
}

.fill-tertiary {
    fill: #B99267;
}

.fill-light {
    fill: #F2F2F2;
}
